@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.object {
  display: flex;
  flex-direction: column;
  z-index: -1000;
  max-width: 1080px;
  height: 100%;
  overflow-y: scroll;
  margin-left: auto;
  margin-right: auto;

  &Full {
    @extend .object;

    top: 0;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 55px;
    width: 55px;
    top: 45%;
    left: 2%;
    transform: rotate(90deg);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    border: none;
    z-index: 1000;

    &Right {
      @extend .object__button;

      transform: rotate(-90deg);
      left: auto;
      right: 2%;
    }

    :hover {
      opacity: 0.8;
    }
  }

  &__buttonDisable {
    @extend .object__button;

    opacity: 0.7;

    &Right {
      @extend .object__buttonRight;
    }
  }

  &__descriptionBox {
    padding: 15px;
  }

  &__imageBox {
    display: flex;
    position: relative;
    overflow-x: scroll;
    max-width: 100%;
    min-height: 232px;
    height: 232px;

    :last-child {
      margin-right: 0px;
    }

    &Full {
      @extend .object__imageBox;

      min-height: 200px;
      height: 200px;
    }
  }

  &__image {
    max-width: 100%;
    height: 100%;
    margin-right: 12px;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;

    @media screen and (min-width: 950px) {
      font-size: $font-size-xll;
      line-height: $line-height-xll;
    }
  }

  &__category {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    color: $color-blue;
    font-style: italic;

    @media screen and (min-width: 950px) {
      font-size: $font-size-xl;
      line-height: $line-height-xl;
    }
  }
}

.info {
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;
  position: relative;

  &__descriptionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__dayBox {
    position: absolute;
    z-index: 1000;
    top: 60px;
    left: 0;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    padding: 0px 15px 20px;
    min-width: 280px;

    &::before {
      content: url(../../assets/tangle.png);
      display: inline-block;
      position: relative;
      top: -18px;
      left: 200px;
    }

    @media screen and (min-width: 950px) {
      top: 80px;
    }
  }

  &__dayBoxDay {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-l;
    line-height: $line-height-xl;

    &Active {
      @extend .info__dayBoxDay;

      font-weight: $font-bold;
      color: $color-green;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;

    @media screen and (min-width: 950px) {
      width: 58px;
      height: 58px;
    }
  }

  &__locationBox {
    display: none;
    position: absolute;
    z-index: 800;
    top: 160px;
    left: 0;
    background: #ffffff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    padding: 10px 25px 10px 0;
    min-width: 280px;

    &::before {
      content: url(../../assets/tangle.png);
      display: inline-block;
      position: relative;
      top: -28px;
      left: 200px;
    }

    @media screen and (min-width: 950px) {
      display: flex;
      top: 180px;
    }
  }

  &__logoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #cccccc;
    margin-top: 3rem;
    padding-top: 3rem;
    width: 100%;

    @media screen and (min-width: 950px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__logoBoxParagraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    color: #7e8d94;
    font-style: italic;
    max-width: 210px;
    margin-bottom: 20px;

    @media screen and (min-width: 950px) {
      margin-bottom: 0;
    }
  }

  &__logoBoxLogo {
    max-width: 225px;
    margin-top: 5px;
    margin-bottom: 5px;

    @media screen and (min-width: 950px) {
      flex-direction: row;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  &__photo {
    margin: 2rem auto 3rem auto;
    max-width: 100%;
    max-height: 450px;
  }

  &__paragraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-bottom: 10px;
  }

  &__timeArrow {
    fill: $color-green;
    width: 15px;
    height: 25px;
  }

  &__timeButton {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    background-color: inherit;
    border: none;
  }

  &__timeLabel {
    font-weight: $font-regular;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-right: 10px;

    &Close {
      @extend .info__timeLabel;

      font-size: 13px;
      font-weight: $font-bold;

      @media screen and (min-width: 950px) {
        font-size: $font-size-l;
      }
    }
  }
}

.infoLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__linkToMap {
    display: flex;
    align-items: center;
  }

  &__paragraph {
    font-weight: $font-regular;
    font-size: $font-size-m;
    line-height: $line-height-m;
    margin-left: $margin-m;
  }
}

.infoRight {
  display: none;
}

.weather {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 800px) {
      width: 50%;
    }

    &Border {
      @extend .weather__box;

      border-bottom: 1px solid #cccccc;
      padding-bottom: 15px;

      @media screen and (min-width: 800px) {
        padding-bottom: 0;
        border-bottom: none;
        border-right: 1px solid #cccccc;
      }
    }
  }

  &__icon {
    width: 130px;
    height: 130px;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: 2.4rem;
    line-height: $line-height-xll;
    text-align: center;
  }

  &__temperature {
    margin-top: $margin-m;
    font-weight: $font-semiBold;
    font-size: 30px;
    text-align: center;

    @media screen and (min-width: 800px) {
      font-size: $font-size-xll;
    }
  }
}

.location {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: $font-size-xl;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    font-size: 36px;
  }

  &__name {
    display: flex;
    align-items: center;
    font-size: $font-size-xl;
    font-weight: $font-semiBold;
    font-style: italic;
    color: $color-green;
    text-decoration: underline;
  }

  &__icon {
    width: 44px;
    height: 44px;
    margin: 0 15px;
  }
}

.berg {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.footer {
  display: flex;
}

.footerDisabled {
  display: none;
}
