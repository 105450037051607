@import '../../theme/variables';

.header {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.35);
  position: relative;
  z-index: 10000;
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 0.5px solid #c4c4c4;
}

.logo {
  position: static;
  width: 15rem;
  height: 30px;

  &__description {
    letter-spacing: 1px;
    font-size: 7px;
    font-weight: $font-semiBold;
    color: $color-green;
    text-align: left;
    line-height: 0;
  }

  &__logo {
    width: 13rem;
  }
}

.weather {
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
    background-color: inherit;
    border: none;
    outline-style: none;
  }

  &__flag {
    width: 3.2rem;
    height: 3.8rem;
  }

  &__flagEn {
    width: 3.3rem;
    height: 3.8rem;
  }

  &__paragraph {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-left: 0.6rem;
  }
}
