@import '../../theme/variables';

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: $font-size-xl;
  font-weight: $font-semiBold;
  line-height: $line-height-xll;
  color: $color-green;
}
