@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.object {
  display: flex;
  flex-direction: column;
  z-index: -1000;
  height: 100%;
  overflow-y: scroll;
  top: 0;

  &__descriptionBox {
    padding: 15px;
  }

  &__imageBox {
    display: flex;
    position: relative;
    overflow-x: scroll;
    max-width: 100%;
    min-height: 250px;
    height: 250px;

    :last-child {
      margin-right: 0px;
    }
  }

  &__image {
    max-width: 100%;
    height: 100%;
    margin-right: 12px;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: 30px;
    line-height: 45px;
  }

  &__category {
    font-weight: $font-semiBold;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    color: $color-blue;
    font-style: italic;
  }
}

.info {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;
  position: relative;

  &__descriptionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 58px;
    height: 58px;
  }

  &__logoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #cccccc;
    margin-top: 3rem;
    padding-top: 3rem;
  }

  &__logoBoxParagraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    color: #7e8d94;
    font-style: italic;
    max-width: 210px;
  }

  &__logoBoxLogo {
    max-width: 225px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__photo {
    margin: 2rem auto 3rem auto;
    max-width: 100%;
    max-height: 450px;
  }

  &__paragraph {
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-bottom: 10px;
  }
}

.infoRight {
  &__box {
    display: none;
  }

  &__paragraph {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    margin-top: $margin-s;
    text-align: center;
    color: $color-green;
  }

  &__qr {
    display: none;
  }
}

.weather {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $padding-l;
  border-bottom: 1px solid #cccccc;

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Border {
      @extend .weather__box;

      border-bottom: 1px solid #cccccc;
      padding-bottom: 15px;
    }
  }

  &__icon {
    width: 130px;
    height: 130px;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: 2.4rem;
    line-height: $line-height-xll;
    text-align: center;
  }

  &__temperature {
    margin-top: $margin-m;
    font-weight: $font-semiBold;
    font-size: 30px;
    text-align: center;
  }
}

.location {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: $font-size-xl;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    font-size: 36px;
  }

  &__name {
    display: flex;
    align-items: center;
    font-size: $font-size-xl;
    font-weight: $font-semiBold;
    font-style: italic;
    color: $color-green;
    text-decoration: underline;
  }

  &__icon {
    width: 44px;
    height: 44px;
    margin: 0 15px;
  }
}
