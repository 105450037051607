@import '../../theme/variables';

a {
  text-decoration: none;
  color: inherit;
}

.objectPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: $font-size-xl;
  font-weight: $font-semiBold;
  line-height: $line-height-xll;
  color: $color-green;
}

.objects {
  display: block;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
